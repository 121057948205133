'use strict'

import ApplicantBlock from '@js/model/ApplicantBlock';

export default class ApplicantBlockManageButtonsView {
    /** @private @constant {string} #MANAGE_BUTTON ブロック登録ボタンのセレクタ */
    BUTTONS = '.btn-applicant-block-manage';

    /** @private @type {ApplicantBlock} #applicantBlock ApplicantBlock モデル*/
    #applicantBlock;

    /** @private @type {Object} #options オプション */
    #options = {
        afterManage: undefined,
        buttons: undefined,
    }

    /**
     * コンストラクタ
     *
     * @param {Object} options オプション
     */
    constructor(options) {
        this.#applicantBlock = new ApplicantBlock();
        this.#options = { ...this.#options, ...options };

        if (typeof this.getButtons() === 'undefined') {
            const buttons = $(this.BUTTONS).get();
            this.setButtons(buttons);
        }
    }

    /**
     * ボタンを取得
     *
     * @returns {*} ボタン
     */
    getButtons() {
        return this.#options.buttons;
    }

    /**
     * ボタンの初期化
     *
     * @returns {this}
     */
    initButtons() {
        $(this.getButtons())
            .off('click')
            .on('click', (event) => {
                const button = event.currentTarget;
                const $button = $(button);
                $button.prop('disabled', true);

                const mode = $button.data('mode');

                const data = {
                    corp_id: $button.data('client-corp-id'),
                    mode: mode,
                };

                this.#applicantBlock
                    // 成功時のコールバック
                    .setOption('afterManage', () => {
                        return this.#afterManageCallback(button, mode)
                    })
                    // 終了時のコールバック
                    .setOption('onManageFinally', () => {
                        $button.prop('disabled', false);
                    })
                    .manage(data);
            });

        return this;
    }

    /**
     * ボタンをセット
     *
     * @param {*} buttons ボタン
     * @returns {this}
     */
    setButtons(buttons) {
        this.#options.buttons = buttons;

        return this;
    }

    /**
     * オプションセット
     *
     * @param {string} name オプション名
     * @param {*} value 値
     * @returns {this}
     */
    setOption(name, value) {
        if (this.#options.hasOwnProperty(name)) {
            this.#options[name] = value;
        }

        return this;
    }

    /**
     * 処理成功後のコールバック
     *
     * @param {HTMLElement} button ボタン
     * @param {string} mode モード
     * @returns {boolean|void}
     */
    #afterManageCallback(button, mode) {
        const afterManage = this.#options.afterManage;
        if (typeof afterManage === 'function') {
            return afterManage(button, mode);
        }

        const nextMode = (mode === 'add') ? 'remove' : 'add';
        this.#switchButton(button, nextMode);

        return false;
    }

    /**
     * ボタンの表示切替
     *
     * @param {HTMLButtonElement} button 対象のボタン
     * @param {string} mode 操作モード
     */
    #switchButton(button, mode) {
        const $button = $(button);
        $button.data('mode', mode);

        // アイコン
        const isIconOnly = typeof $button.data('icon-only') !== 'undefined';
        if (isIconOnly) {
            const fill = mode === 'remove' ? 'red' : '';
            $button.find('path').attr('fill', fill);
        } else {
            const $use = $button.find('use');
            const iconUrl = $use.data(`href-${mode}`);
            $use.attr('href', iconUrl);
        }

        // テキスト
        const $buttonText = $button.find('.text');
        const buttonText = $buttonText.data(`text-${mode}`);
        $buttonText.text(buttonText);
    }
}
